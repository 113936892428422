<template>
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
    <circle fill="#4CAF50" cx="24" cy="24" r="21"/>
    <g fill="#fff">
      <rect x="21" y="14" width="6" height="20"/>
      <rect x="14" y="21" width="20" height="6"/>
    </g>
  </svg>
</template>

<script>
export default {
name: "PlusIcon"
}
</script>

<style scoped>

</style>