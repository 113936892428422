<template>
  <div>
    <h3>FAQ</h3>
    <hr>
    <b-row>
      <b-col>
        <h5>If I buy the digital edition, will I receive the book updates?</h5>
        <p>Yes! When I publish a new release, I will send you an email with the new version.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>Do you offer a bundle option: digital + hard copy?</h5>
        <p>If you are interested in buying a bundle, please send me a message.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>What are the shipping options?</h5>
        <p>Now, I offer just one shipping option. I use the <b-link href="https://en.wikipedia.org/wiki/La_Poste_(France)">French national Post Service</b-link>.
          They offer worldwide shipping options.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>I cannot afford the book price, can I have a discount?</h5>
        <p>Please contact me directly via the chatbox, and let's discuss! Please also note that I give a 10% discount for teachers and students. You can ask for your coupon <b-link :to="{name:this.routeNames.ASK_COUPON}">here</b-link>.</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5>I'm living outside Europe. Can you ship the book internationally?</h5>
        <p>Yes. I use the <b-link href="https://en.wikipedia.org/wiki/La_Poste_(France)">French national Post Service</b-link>. They offer worldwide shipping options. Click on order and select your country.</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Consts from "@/consts";

export default {
name: "FAQ",
  data() {
    return {
      routeNames : Consts.ROUTE_NAMES
    }
  },
}
</script>

<style scoped>

</style>