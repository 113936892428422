<template>
  <div>
    <NavBar></NavBar>
    <b-container>
      <h4 class="mt-2">Hard Copy / E-Book</h4>
      <hr>
      <b-row cols-md="2" cols="1" class="mb-5">
        <b-col>
          <p>If you prefer to read a book made of <strong>paper</strong>, you can buy here the hard copy !</p>

          <p>I also propose a <strong>digital version (PDF, EPUB and MOBI)</strong>.
            <b-link :href="`${publicPath}practical-go-lessons-preview.pdf`">See PDF Preview</b-link>
          </p>
          <p>I wrote the book using <strong>LaTeX</strong>. Consequently, the output format is well-formatted.</p>
          <p>Here are some characteristics of the book:</p>
          <ul>
            <li>It is composed of <strong>{{ pageCount }}</strong> pages.</li>
            <li><strong>41</strong> chapters</li>
            <li>With a detailed table of contents</li>
            <li>A bibliography</li>
            <li>And an <strong>index</strong> referencing the important notions</li>
            <li>Available in color or black and white</li>
            <li><b-link @click="onClickCouponTeacher">10% discount for teachers and students</b-link></li>
          </ul>
          <b-row>
            <b-col>
              <WeAcceptPaypal></WeAcceptPaypal>
            </b-col>
            <b-col class="text-center my-auto ">
              <PoweredByStripe width="200px"></PoweredByStripe>
            </b-col>
          </b-row>

        </b-col>
        <b-col>
          <b-carousel
              :interval="4000"
              controls
              indicators
              class="mb-2">
            <b-carousel-slide v-for="(img,idx) in imagesSwiper" :key="idx" :img-src="img.url"></b-carousel-slide>
          </b-carousel>


        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <strong>Select your currency</strong>
          <b-form-group>
            <b-form-select v-on:input="onChangeCurrency" v-model="currency" :options="currencyOptions"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row cols="1" cols-md="5" class="mb-3">
        <b-col class="border">
          <div class="product-header">
            <h2>Digital</h2><br>
            <hr>
            <h1>{{ mainPrices["DIGITAL"][currency] }}</h1>
            <br>
            <b-button type="button" size="lg" block variant="outline-success"  @click="onClickOrderDigital()">Order</b-button>
            <hr>
          </div>
          <DigitalDescription></DigitalDescription>
        </b-col>

        <b-col class="border ">
          <div class="product-header">
            <h2>Paper</h2>
            <small>Black and White Edition</small>
            <hr>
            <h1>{{ mainPrices["HARD_BW"][currency] }}</h1>
            <br>
            <b-button type="button" size="lg" block variant="outline-success" @click="onOrderPhysicalClick('HARD_BW')">Order</b-button>
            <hr>
          </div>
          <PaperBWDescription></PaperBWDescription>
        </b-col>

        <b-col class="border ">
          <div class="product-header">
            <h2>Paper</h2>
            <small>Color Edition</small>
            <hr>
            <h1>{{ mainPrices["HARD_COLOR"][currency] }}</h1>
            <br>
            <b-button type="button" size="lg" block variant="outline-success" @click="onOrderPhysicalClick('HARD_COLOR')">Order</b-button>
            <hr>
          </div>
          <PaperColorDescription></PaperColorDescription>
        </b-col>

        <b-col class="border">
          <div class="product-header">
            <h2>Paper + Digital</h2>
            <small>Black and White Edition</small>
            <hr>
            <h1>{{ mainPrices["BUNDLE_BW"][currency] }}</h1>
            <br>
            <b-button type="button" size="lg" block variant="outline-success" @click="onOrderPhysicalClick('BUNDLE_BW')">Order</b-button>
            <hr>
          </div>
          <BundleBWDescription></BundleBWDescription>
        </b-col>

        <b-col class="border">
          <div class="product-header">
            <h2>Paper + Digital</h2>
            <small>Color Edition</small>
            <hr>
            <h1>{{ mainPrices["BUNDLE_COLOR"][currency] }}</h1>
            <br>
            <b-button type="button" size="lg" block variant="success" @click="onOrderPhysicalClick('BUNDLE_COLOR')">Order</b-button>
            <hr>
          </div>
          <BundleColorDescription></BundleColorDescription>
        </b-col>
      </b-row>


      <b-row cols-md="2" cols="1">
        <b-col>
          <h4>More images</h4>
          <hr>
          <b-carousel
              :interval="4000"
              controls
              indicators
              img-width="300px"
              img-height="480px"
              class="mb-2">
            <b-carousel-slide v-for="(img,idx) in imagesSwiperFooter" :key="idx" :img-src="img.url"></b-carousel-slide>
          </b-carousel>
        </b-col>
        <b-col>
          <FAQ></FAQ>

        </b-col>
      </b-row>

      <b-modal id="modal-digital-edition" :title="`Order Digital Edition`" hide-footer>
        <AcceptCGV :show-c-g-v-alert="showCGVAlert" v-on:cgv-accepted="onCgvAccepted"
                   id-check-box="check-DIGITAL"></AcceptCGV>
        <br>
        <b-button block variant="success" :disabled="disableOrderButtonDigitalStripe" @click="onOrderDigitalStripe">
          <b-spinner v-if="showSpinnerDigitalStripe" small></b-spinner>
          Pay With Card (Stripe)
        </b-button>
        <b-button block :disabled="disableOrderButtonDigitalStripe" variant="info" @click="onClickPayWithPaypal">Pay With
          Paypal
        </b-button>
      </b-modal>

      <b-modal id="modal-1" :title="`Order ${bookTypeDisplay}`" hide-footer @hide="onModalPhysicalHide">
        <CountrySelector v-on:input="countrySelected"></CountrySelector>
        <br>
        <p><strong>Shipping Costs:</strong> {{ shippingPrice }}</p>

        <p>The book is printed <strong>on demand</strong>. It takes approximately 3 business days to print a batch of
          books.
          I will send you the book as soon as it's printed.</p>
        <AcceptCGV :show-c-g-v-alert="showCGVAlert" v-on:cgv-accepted="onCgvAccepted"
                   :id-check-box="'check-'+currentlyOrderedItemType"></AcceptCGV>
        <b-button block variant="success" :disabled="disableOrderButton" @click="onPayClickPhysical">
          <b-spinner v-if="showSpinner" small></b-spinner>
          Pay With Card (Stripe)
        </b-button>
        <b-button block :disabled="disableOrderButton" variant="info" @click="onClickPayWithPaypal">Pay With
          Paypal
        </b-button>

      </b-modal>


      <b-modal id="modal-coupon-teacher" title="Teacher/Student discount" hide-footer>
        <p>If you are a teacher or a student, I can give you a coupon to buy the book at a lower price.</p>
        <p>To get this coupon, please click on the button:</p>
        <b-button variant="success" :to="{name :routeNameCoupon}">Ask Coupon</b-button>
      </b-modal>

    </b-container>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import Webservice from "@/webservice";
import ScriptLoader from "@/scriptLoader";
import CountrySelector from "@/components/CountrySelector";
import Consts from "@/consts";
import FAQ from "@/components/FAQ";
import AcceptCGV from "@/components/AcceptCGV";
import WeAcceptPaypal from "@/components/WeAcceptPaypal";
import PoweredByStripe from "@/components/PoweredByStripe";
import DigitalDescription from "@/components/DigitalDescription";
import PaperBWDescription from "@/components/PaperBWDescription";
import PaperColorDescription from "@/components/PaperColorDescription";
import BundleBWDescription from "@/components/BundleBWDescription";
import BundleColorDescription from "@/components/BundleColorDescription";

const title = "Buy a Copy - Practical Go Lessons Book"
const description = "Buy a digital or hard copy of the book. More than 700 pages of Go"
export default {
  name: "Buy",
  components: {
    BundleColorDescription,
    BundleBWDescription,
    PaperColorDescription,
    PaperBWDescription,
    DigitalDescription,
    PoweredByStripe,
    WeAcceptPaypal,
    AcceptCGV, FAQ, CountrySelector, Footer, NavBar},
  data() {
    return {
      paymentInProgress: false,
      currencyOptions: [
        {value: "EUR", text: 'EURO (€)'},
        {value: 'USD', text: 'US Dollar ($)'},
      ],
      currency: "EUR",
      mainPrices: Consts.MAIN_PRICES,
      showCGVAlert: false,
      cgvAccepted: false,
      // Stripe digital
      disableOrderButtonDigitalStripe: false,
      showSpinnerDigitalStripe: false,
      // Paypal digitial
      disableOrderButtonDigitalPaypal: false,
      showSpinnerDigitalPaypal: false,

      publicPath: process.env.BASE_URL,
      imagesSwiperFooter: [
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book-review-mobi.png'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book-review-mobi2.png'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book-review-epub.png'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book2.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book3.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book4.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book7.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book9.jpg'),
          alt: "Practical Go lessons Book image",
        },
      ],
      imagesSwiper: [
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book10.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book5.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book6.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book8.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book11.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book12.jpg'),
          alt: "Practical Go lessons Book image",
        },
        {
          url: require('@/assets/images/image-book/practical-go-lessons-book13.jpg'),
          alt: "Practical Go lessons Book image",
        }
      ],
      pageCount: "758",
      contextForm: '',
      showCountrySelector: false,
      currentlyOrderedItemType: '',
      shippingPrice: '--',
      disableOrderButton: true,
      bookTypeDisplay: '',
      shippingCountryCode: '',
      showSpinner: false,
      routeNameCoupon: Consts.ROUTE_NAMES.ASK_COUPON,
      address: {
        firstname: "",
        lastname: "",
        line1: "",
        line2: "",
        adminArea1: "",
        adminArea2: "",
        postalCode: "",
        countryCode: ""
      },
      paypalButtonLoaded: false,
      bookModel: '',
      showPaypalButtonPhysical: false,
      showAddressForm: true,
      showAddressRecap: false
    }
  },
  metaInfo: {
    title: title,
    htmlAttrs: {
      lang: 'en',
    },
    meta: [
      {charset: 'utf-8'},
      {name: 'description', content: description},
      {name: 'robots', content: "index, follow"},
      {property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META},
      {property: 'og:type', content: "website"},
      {property: 'og:title', content: title},
      {property: 'og:description', content: description},
      {property: 'og:url', content: window.location.href},
      {property: 'og:site_name', content: 'Practical Go Lessons'},
      {property: 'twitter:card', content: "summary_large_image"},
      {property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME}
    ],
    link: [
      {rel: "canonical", href: window.location.href}
    ]

  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    onModalPhysicalHide(){
      // reset all
      this.cgvAccepted = false
      this.showCGVAlert = false
      this.shippingCountryCode = ""
      this.address.countryCode = ""
      this.disableOrderButton = true
    },
    onClickPayWithPaypal() {
      if (!this.cgvAccepted) {
        this.showCGVAlert = true
        return
      }
      this.$router.push({name:Consts.ROUTE_NAMES.CHECKOUT_PAYPAL, query:{model:this.currentlyOrderedItemType,countryCode:this.shippingCountryCode, currency : this.currency}})
    },
    onChangeCurrency(newVal) {
      this.shippingPrice = Consts.ISO_TO_PRICE[this.shippingCountryCode][newVal]
    },
    onClickOrderDigital() {
      this.cgvAccepted = false
      this.showCGVAlert = false
      this.currentlyOrderedItemType = Consts.BOOK_TYPES_ENUM.DIGITAL
      this.$bvModal.show("modal-digital-edition")
    },
    onCgvAccepted(accepted) {
      this.cgvAccepted = accepted
    },
    onClickCouponTeacher() {
      this.$bvModal.show("modal-coupon-teacher")
    },
    onPayClickPhysical() {
      if (!this.cgvAccepted) {
        this.showCGVAlert = true
        return
      }
      this.showSpinner = true
      this.disableOrderButton = true
      ScriptLoader.loadStripe().then(() => {
        let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
        Webservice.createCheckout({
          provider: "STRIPE",
          model: this.currentlyOrderedItemType,
          shippingCountryCode: this.shippingCountryCode,
          currency: this.currency
        }).then((res) => {
          stripe.redirectToCheckout({sessionId: res.data.sessionId});
        }).catch((err) => {
          this.showSpinner = false
          this.disableOrderButton = false
          console.error(err)
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err) => {
        this.showSpinner = false
        this.disableOrderButton = false
        console.error(err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      });
    },
    countrySelected(sel) {
      this.shippingCountryCode = sel
      this.address.countryCode = sel
      this.shippingPrice = Consts.ISO_TO_PRICE[this.shippingCountryCode][this.currency]
      this.disableOrderButton = false
    },
    onOrderPhysicalClick(itemType) {
      this.cgvAccepted = false
      this.showCGVAlert = false
      this.bookTypeDisplay = Consts.BOOK_TYPE_DISPLAY[itemType]
      this.currentlyOrderedItemType = itemType
      this.$bvModal.show("modal-1")
    },
    onOrderDigitalStripe() {
      if (!this.cgvAccepted) {
        this.showCGVAlert = true
        return
      }
      this.showSpinnerDigitalStripe = true
      this.disableOrderButtonDigitalStripe = true
      ScriptLoader.loadStripe().then(() => {
        let stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
        Webservice.createCheckout({
          provider: "STRIPE",
          model: "DIGITAL",
          currency: this.currency,
        }).then((res) => {
          stripe.redirectToCheckout({sessionId: res.data.sessionId});
        }).catch((err) => {
          this.showSpinnerDigitalStripe = false
          this.disableOrderButtonDigitalStripe = false
          console.error(err)
          this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
        })
      }).catch((err) => {
        this.showSpinnerDigitalStripe = false
        this.disableOrderButtonDigitalStripe = false
        console.error(err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      });

    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>
.product-header {
  text-align: center !important;
  margin-top: 0.5rem !important;
}
</style>