<template>
  <div class="ml-3">
    <ul class="list-unstyled">
      <li><BIconFiles variant="success" class="mr-2"></BIconFiles> 758 pages</li>
      <li><ColorPaletteIcon style="width: 17px" class="mr-2"></ColorPaletteIcon> <span id="grad1">Printed in Color</span></li>
      <li><BIconDownload variant="success" class="mr-2"></BIconDownload> All source code examples</li>
      <li><BIconDroplet variant="success" class="mr-2"></BIconDroplet> Printed in Europe</li>
      <li><BIconBoxSeam variant="success" class="mr-2"> </BIconBoxSeam> Shipped to 183 countries</li>
    </ul>
    <ul class="list-unstyled mt-3 mb-4">
      <li><strong>Size:</strong> 17cm * 24 cm</li>
      <li><strong>Binding:</strong> Hard heat-sealed binding professional</li>
      <li><strong>Paper:</strong> 90gr - Classic uncoated - Wood free</li>
      <li><strong>Weight:</strong> approx. 1,47 kg</li>
      <li><strong>Cover:</strong> Mat laminating</li>
    </ul>
  </div>
</template>

<script>
import {BIconBoxSeam,BIconDroplet,BIconFiles,BIconDownload } from 'bootstrap-vue'
import ColorPaletteIcon from "@/components/ColorPaletteIcon";
export default {
  name: "PaperColorDescription",
  components: {ColorPaletteIcon, BIconBoxSeam,BIconDroplet,BIconFiles,BIconDownload}

}
</script>

<style scoped>

</style>