<template>
  <div class="ml-3">
    <ul class="list-unstyled">
      <li><BIconDownload variant="success" class="mr-2"></BIconDownload> PDF file</li>
      <li><BIconDownload variant="success" class="mr-2"></BIconDownload> EPUB file</li>
      <li><BIconDownload variant="success" class="mr-2"></BIconDownload> MOBI file</li>
      <li><BIconUnlock variant="success" class="mr-2"></BIconUnlock> All DRM free</li>
      <li><BIconMailbox variant="success" class="mr-2"></BIconMailbox> Free updates sent to your mailbox</li>
      <li><BIconEye variant="success" class="mr-2"></BIconEye> <b-link :href="`${publicPath}practical-go-lessons-preview.pdf`">See PDF Preview</b-link></li>

    </ul>

  </div>
</template>

<script>
import {BIconDownload,BIconUnlock,BIconMailbox,BIconEye } from 'bootstrap-vue'

export default {
  name: "DigitalDescriptionLight",
  components: {BIconDownload,BIconUnlock,BIconMailbox,BIconEye},
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
}
</script>

<style scoped>

</style>